<template>
  <div class="nlp-input">
    <textarea
      class="input-textarea"
      placeholder="ใส่ข้อความที่นี่"
      v-model="text_select"
    >
    </textarea>
    <div class="d-flex">
      <div class="radio-item" v-for="(type,index) of typeOptions" :key="index">
        <input
          type="radio"
          :id="type.value"
          name="radio"
          :value="type.value"
          v-model="type_select"
        />
        <label :for="type.value">{{ type.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NLPInput',
  props: {
    value: {
      type: Object,
      default: function () {
        return { 
          text: '',
          type: 'ner'
        }
      }
    }
  },
  data() {
    return {
      typeOptions: [
        { name: 'คำเฉพาะ', value: 'ner', keyUsed: 'entity' },
        { name: 'หลักไวยากรณ์', value: 'summary', keyUsed: 'pos', keyGroup: "role" },
      ],
    }
  },
  computed: {
    type_select: {
      get: function() {
        return this.value.type
      },
      set: function(newValue) {
        this.$emit("input", {
          text: this.value.text,
          type: newValue
        })
      }
    },
    text_select: {
      get: function() {
        return this.value.text
      },
      set: function(newValue) {
        this.$emit("input", {
          text: newValue,
          type: this.value.type
        })
      }
    }
  }
}
</script>

<style scoped>
.nlp-input::v-deep .input-textarea {
  width: 100%;
  height: 450px;
  background: #FFFFFF;
  border: 2px solid #40e0d0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px 21px;
  resize: none;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 200%;
}

.nlp-input::v-deep .input-textarea:focus-visible {
  outline: none;
}

.radio-item {
  display: inline-block;
  position: relative;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  color: #FFFFFF;
  display: flex;
  margin: 15px 41px 0 33px;
}

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid #FB7A1E;
  background-color: transparent;
  margin: 0 18px 0 0;
}

.radio-item input[type='radio']:checked + label:after {
  border-radius: 20px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 24px;
  left: 37px;
  content: ' ';
  display: block;
  background: #FB7A1E;
}

@media screen and (min-width: 375px) and (max-width: 600px) {
  .radio-item label {
    font-style: normal;
    font-weight: 700;
    font-size: 6.4vw;
    line-height: 135%;
    color: #FFFFFF;
    display: flex;
    margin: 4vw 0 0 2.667vw;
  }

  .radio-item label:before {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 1.333vw;
    width: 5.333vw;
    height: 5.333vw;
    border-radius: 5.333vw;
    border: 2px solid #FB7A1E;
    background-color: transparent;
    margin: 0 4.8vw 0 0;
  }

  .radio-item input[type='radio']:checked + label:after {
    border-radius: 20px;
    width: 3.2vw;
    height: 3.2vw;
    position: absolute;
    top: 6.4vw;
    left: 3.7vw;
    content: ' ';
    display: block;
    background: #FB7A1E;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .radio-item label {
    font-size: 2.3vw;
    margin: 1.95vw auto 0 1.2vw;
  }

  .radio-item label:before {
    top: 0.26vw;
    width: 2.6041666666667vw;
    height: 2.6041666666667vw;
  }

  .radio-item input[type='radio']:checked + label:after {
    top: 2.73vw;
    left: 1.69vw;
    width: 1.5625vw;
    height: 1.5625vw;
  }
}

/* width */
.nlp-input::v-deep .input-textarea::-webkit-scrollbar {
  width: 8px;
  height:8px;
}
/* Track */
.nlp-input::v-deep .input-textarea::-webkit-scrollbar-track {
  box-shadow: inset 10px 10px 10px rgb(224, 224, 224, 0.5); 
  border-radius: 10px;
}

/* Handle */
.nlp-input::v-deep .input-textarea::-webkit-scrollbar-thumb {
  background: #FB7A1E;
  border-radius: 10px;
}

/* Handle on hover */
.nlp-input::v-deep .input-textarea::-webkit-scrollbar-thumb:hover {
  background: #FB7A1E; 
}
</style>
