<template>
  <div class="result-section">
    <slot name="result-value">123123132</slot>
  </div>
</template>

<script>
export default {
  name: 'NLPResultLeft',
}
</script>

<style scoped>
.result-section::v-deep {
  width: 100%;
  height: 450px;
  background: #FFFFFF;
  border: 2px solid #40e0d0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px 21px;
  resize: none;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 200%;
  overflow-y: scroll;
}
/* width */
.result-section::v-deep::-webkit-scrollbar {
  width: 8px;
  height:8px;
}
/* Track */
.result-section::v-deep::-webkit-scrollbar-track {
  box-shadow: inset 10px 10px 10px rgb(224, 224, 224, 0.5); 
  border-radius: 10px;
}

/* Handle */
.result-section::v-deep::-webkit-scrollbar-thumb {
  background: #FB7A1E;
  border-radius: 10px;
}

/* Handle on hover */
.result-section::v-deep::-webkit-scrollbar-thumb:hover {
  background: #FB7A1E; 
}
</style>
