<template>
  <div class="result-section">
    <div class="result-title">Result</div>
    <slot name="result-value"></slot>
    <!-- <div class="result-document" v-if="!isRunExtraction"></div>
    <div class="result-document" v-else>
      Text:{{ outputText }} Type:{{ outputType }}
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'NLPResult',
}
</script>

<style scoped>
.result-section::v-deep {
  height: 595px;
  border: 2px solid #40e0d0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 18px 32px;
  background-color: #FFFFFF;
  overflow-y: scroll;
}

.result-section::v-deep .result-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  color: #000000;
}
.result-section::v-deep .result-document {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 250%;
  color: #000000;
  height: 585px;
}

/* width */
.result-section::v-deep::-webkit-scrollbar {
  width: 8px;
  height:8px;
}
/* Track */
.result-section::v-deep::-webkit-scrollbar-track {
  box-shadow: inset 10px 10px 10px rgb(224, 224, 224, 0.5); 
  border-radius: 10px;
}

/* Handle */
.result-section::v-deep::-webkit-scrollbar-thumb {
  background: #FB7A1E;
  border-radius: 10px;
}

/* Handle on hover */
.result-section::v-deep::-webkit-scrollbar-thumb:hover {
  background: #FB7A1E; 
}
</style>
